:root {
  /* Colors (http://clrs.cc/) */
  --aqua: #7fdbff;
  --blue: #0074d9;
  --navy: #001f3f;
  --teal: #39cccc;
  --green: #2ecc40;
  --olive: #3d9970;
  --lime: #01ff70;

  --yellow: #ffdc00;
  --orange: #ff851b;
  --red: #ff4136;
  --fuchsia: #f012be;
  --purple: #b10dc9;
  --maroon: #85144b;

  --white: #ffffff;
  --silver: #dddddd;
  --gray: #aaaaaa;
  --black: #111111;

  /* Override terminal.css colors */
  --primary-color: var(--blue);
  --error-color: var(--red);

  /* Override terminal.css fonts */
  --font-stack: "iA Writer Quattro", Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  --mono-font-stack: "iA Writer Mono", Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --global-font-size: 15px;
    --global-line-height: 1.4em;
    --global-space: 10px;
    --background-color: #222225;
    --page-width: 60em;
    --font-color: #e8e9ed;
    --invert-font-color: #222225;
    --secondary-color: #a3abba;
    --tertiary-color: #a3abba;
    --primary-color: #62c4ff;
    --error-color: #ff3c74;
    --progress-bar-background: #3f3f44;
    --progress-bar-fill: #62c4ff;
    --code-bg-color: #3f3f44;
    --input-style: solid;
    --display-h1-decoration: none;
  }
}
