@import "terminal.css";

@import "_fonts.css";
@import "_variables.css";
@import "_general.css";

#posts {
  .meta {
    color: var(--secondary-color);
  }

  img {
    width: auto;
    max-width: 90%;
    max-height: 128px;
    padding-bottom: 1em;
    display: block;
    clear: both;
  }

  video {
    max-width: 100%;
  }
}

#introduction {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

  .profile {
    float: right;
    border-radius: 100%;
    background: var(--white);
    width: 140px;
    height: 140px;
    shape-outside: circle(50%);
  }

  @media screen and (max-width: 768px) {
    .profile {
      width: 100px;
      height: 100px;
    }
  }

  .column {
    margin: 10px;
    align-self: auto;
  }

  .column:nth-child(1) {
    order: 0;
    flex: 3;
  }

  .column:nth-child(2) {
    order: 1;
    flex: 1;
  }
}

.spinner {
  &::before {
    content: "|";
    animation: spinner 1s linear infinite;
  }
}

.loading {
  &::after {
    content: "";
    animation: dots 2s linear infinite;
  }

  &.delay-0::after {
    animation-delay: 0;
  }

  &.delay-1::after {
    animation-delay: 500ms;
  }

  &.delay-2::after {
    animation-delay: 1000ms;
  }

  &.delay-3::after {
    animation-delay: 1500ms;
  }

  &.delay-4::after {
    animation-delay: 2000ms;
  }
}

@keyframes dots {
  0% {
    content: "";
  }

  25% {
    content: ".";
  }

  50% {
    content: "..";
  }

  75% {
    content: "...";
  }
}

@keyframes spinner {
  0% {
    content: "|";
  }

  14.3% {
    content: "/";
  }

  28.6% {
    content: "—";
  }

  42.9% {
    content: "\\";
  }

  57.1% {
    content: "|";
  }

  71.4% {
    content: "/";
  }

  85.7% {
    content: "—";
  }

  100% {
    content: "\\";
  }
}
