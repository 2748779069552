/* Override the default width in terminal.css */
img {
  width: auto;
  max-width: 100%;
}

/* Make all of the blockquote grey */
blockquote p {
  color: var(--secondary-color);
}

.terminal code {
  font-family: var(--mono-font-stack);
}

a:hover {
  background-color: inherit;
  color: var(--primary-color);
  text-decoration: underline;
}

h1 a:hover {
  background-color: var(--primary-color);
  color: var(--invert-font-color);
  text-decoration: none;
}

/* Match the margin on the blog */
.container + .container {
  margin-top: var(--global-line-height);
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.social-nav {
  ul {
    list-style-type: none;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    font-size: var(--global-font-size);
    margin-top: 0;
  }

  li {
    display: flex;
    margin: 0 1rem 0.5rem 0;
    padding: 0;
  }

  .menu-item {
    .icon {
      fill: var(--secondary-color);

      &:hover {
        fill: var(--font-color);
      }
    }
  }
}
